import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
// import loading from '../assets/images/loading.svg'
import styles from '../styles/Home.module.css';
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import {Dialog} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import SEO from "./SEO";

import HomeNewsEntrance from './HomeNewsEntrance';
import BrainCog_Born from './BrainCog_Born';
import Datasets from './Datasets';
import {Fade} from "react-awesome-reveal";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_bg_original7.jpg';
import {Skeleton} from "antd";

function Home() {
    const {t, i18n} = useTranslation(['home', 'articles', 'html_title', 'common']);
    const isChinese = i18n.language === 'zh';
    const newsList = [t(`news`, {ns: 'articles', returnObjects: true})[0]];console.log(newsList);


    const services = [
        {name: "mind_talk", href: 'http://mind-engine.org/mind-talk/', intro: t("service_intro_mind_talk")},
        {name: "mind_mate", href: 'http://mind-engine.org/mind-mate/', intro: t("service_intro_mind_mate")},
        {name: "symbiosis_panorama", href: 'http://symbiosis-panorama.ai-for-sdgs.academy/cn/', intro: t("service_intro_mind_talk")},
        {name: "cie", href: 'http://cultural-interactions-engine.ai-for-sdgs.academy/', intro: t("service_intro_mind_talk")},
        {name: "digital_philosophers", href: 'http://cn.digital-philosophers.org/', intro: t("service_intro_mind_talk")},
        {name: "sea", href: 'https://safe-and-ethical.ai/', intro: t("service_intro_mind_talk")},
    ];

    const areas = [
        {name: t("organization_name_1"), href: 'javascript:void(0)', intro: t("organization_intro_1")},
        {name: t("organization_name_2"), href: 'javascript:void(0)', intro: t("organization_intro_2")},
        {name: t("organization_name_3"), href: 'javascript:void(0)', intro: t("organization_intro_3")},
        {name: t("organization_name_4"), href: 'javascript:void(0)', intro: t("organization_intro_4")},
        {name: t("organization_name_5"), href: 'javascript:void(0)', intro: t("organization_intro_5")},
    ];

    return (
        <div className="bg-white pb-64">
            <SEO title={t('home', {ns: 'html_title'})}></SEO>
            {/*hero*/}
            <div
                className={`relative isolate px-6 pt-14 lg:px-8 sm:h-screen sm:min-h-[900px] bg-animation ${styles.bg_animation} flex items-center justify-center`}
                style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className="absolute inset-0 -z-1"></div>
                <div className="mx-auto max-w-7xl py-32 sm:py-48 lg:py-48 z-0">
                    {/*                    <div className="hidden sm:mb-16 sm:flex sm:justify-center">
                        <div
                            className="relative rounded-full px-6 py-1 text-sm leading-6 text-gray-300 ring-1 ring-gray-100/50 hover:ring-gray-100/40"
                        >
                            {t("announcing")}.{'  '}
                            <a href="#services" className="font-semibold text-indigo-400">
                                <span className="absolute inset-0" aria-hidden="true"/>
                                {t("get_started")} <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </div>*/}
                    <div className="">
                        <div
                            className={`px-3 text-center text-4xl lg:text-[3.5rem] font-bold bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-[#c00000] to-[#c00000] ${isChinese ? 'leading-normal sm:tracking-[.15em]' : 'tracking-[.01em] leading-1 sm:leading-tight sm:tracking-[.02em]'}`}>
                            {t('hero_heading_1')}
                        </div>
                        <div className={`text-left mt-12 px-3 text-lg font-light font-sans text-neutral-700 sm:text-neutral-700 sm:text-xl leading-tight sm:leading-8 ${isChinese ? 'tracking-[.05em] sm:tracking-[.01em]' : 'leading-6 tracking-[.01em] sm:tracking-[.01em]'}`}>
                            <p>{t('hero_heading_2')}</p>
                            <p className="mt-5">{t('hero_heading_3')}</p>
                        </div>
                        <div className="mt-12 sm:mt-[7rem] flex items-center justify-center gap-x-6">
                            {/*                            <a
                                href="#engines"
                                className="rounded-3xl bg-transparent border-2 border-neutral-400 px-9 pt-2.5 pb-2 text-sm font-normal text-neutral-300 shadow-sm hover:bg-neutral-200/10 duration-150 tracking-[.2em] uppercase"
                            >
                                {t("get_started")}
                            </a>*/}
                            <a href="#areas" id="action_button" className="relative inline-flex h-12 overflow-hidden rounded-none p-[2px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#c00000_50%,#E2CBFF_100%)]"></span>
                                <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-none bg-white px-9 pt-2.5 pb-2 backdrop-blur-3xl text-sm font-normal text-neutral-800 shadow-sm hover:bg-[#c00000]/30 hover:text-white duration-150 tracking-[.2em] uppercase">
                                {t("get_started")}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id={"areas"} className={"flex justify-center items-center flex-col pt-24 pb-12 px-5 bg-white"}>
                <div className={"w-[100%] sm:w-[75%] lg:w-[85%] xl:w-[75%] max-w-7xl mx-auto mb-10"}>
                    <div className={`block text-4xl md:text-5xl bg-clip-text text-transparent bg-[#c00000] sm:bg-gradient-to-b from-[#c00000] to-[#c00000] font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.03em]' : 'tracking-[.02em]'}`}
                    >
                        {t("organization_section_title")}
                    </div>
                </div>
                <div className={"max-w-8xl mx-auto flex flex-wrap justify-space-between items-start"}>
                    {
                        areas.map((item, i) => (
                            <div key={i} className={` ${isChinese ? ' md:min-h-[300px]' : ' md:min-h-[450px]'} border border-neutral-100 hover:border-[#c00000] flex flex-col items-start justify-start md:max-w-[230px] shadow hover:shadow-lg transition duration-200 rounded-lg py-6 px-6 mx-3 my-3`}>
                                <div className={"text-[#c00000] text-lg font-semibold font-sans leading-relaxed min-h-[60px]"}>
                                    {item.name}
                                </div>
                                <div className={"mt-2 text-neutral-600 text-lg font-light font-sans leading-relaxed"}>
                                    {item.intro}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div id={"news"} className={"flex justify-center items-center flex-col pt-16 pb-16 px-5 bg-white"}>
                <div className={"w-[100%] sm:w-[75%] lg:w-[85%] xl:w-[75%] max-w-7xl mx-auto mb-8"}>
                    <div className={`block text-4xl md:text-5xl bg-clip-text text-transparent bg-[#c00000] sm:bg-gradient-to-b from-[#c00000] to-[#c00000] font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.03em]' : 'tracking-[.02em]'}`}
                    >
                        {isChinese ? '最新活动' : 'Recent Activities'}
                    </div>
                </div>
                <div className={"max-w-full sm:max-w-6xl"}>
                    <div className={`md:w-[100%] sm:w-full ${isChinese ? ' md:min-h-[150px]' : ' md:min-h-[150px]'} border border-neutral-100 hover:border-[#c00000] flex flex-col items-start justify-start md:w-[100%] shadow hover:shadow-lg transition duration-200 rounded-lg py-12 px-4 md:px-16 mx-3 my-3`}>
                        <div className={"text-[#c00000] text-3xl font-semibold font-sans leading-relaxed min-h-[60px]"}>
                            {isChinese ? '中国人工智能发展与安全研究网络在联合国大会期间正式公开' : 'Announcing China AI Development and Safety Network (China AISI Network)'}
                        </div>
                        <div className={"max-w-full mt-2 text-neutral-600 text-lg font-light font-sans leading-relaxed"}>
                            {isChinese ?
                                <>
                                    2024年9月25日联合国大会期间，中国人工智能发展与安全研究网络（China AI Development and Safety Network，简称为China AISI Network,{' '}
                                    <a href="/" rel="noopener noreferrer" className="break-all text-red-800">
                                        https://ai-development-and-safety-network.cn
                                    </a>）在纽约联合国总部由中国和赞比亚共同举办的“人工智能能力建设国际合作高级别会议”（High-level Meeting on International Cooperation on Capacity-building of Artificial Intelligence）上正式公开。中国人工智能发展与安全研究网络目前是人工智能安全研究所（AISI）在中国的对口机构。详情请参见联合国网络电视的会议记录（视频42分24秒）：{' '}
                                    <a href="https://webtv.un.org/en/asset/k18/k188rsfh7d" target="_blank" rel="noopener noreferrer" className="break-all text-red-800">
                                        https://webtv.un.org/en/asset/k18/k188rsfh7d
                                    </a>.
                                </>
                                :
                                <>
                                    The China AI Development and Safety Network (China AISI Network,{' '}
                                    <a href="/" rel="noopener noreferrer" className="break-all text-red-800">
                                        https://ai-development-and-safety-network.cn
                                    </a>) was officially announced on September 25th, 2024 at the High-level Meeting on International Cooperation on Capacity-building of Artificial Intelligence (co-hosted by China and Zambia), at the headquarters of UN in New York, during the 2024 UN General
                                    Assembly. The China AISI Network currently serves as the counterpart of the AI Safety Institutes (AISI) in China. The recording from UN Web TV is available at (around 42:24){' '}
                                    <a href="https://webtv.un.org/en/asset/k18/k188rsfh7d" target="_blank" rel="noopener noreferrer" className="break-all text-red-800">
                                        https://webtv.un.org/en/asset/k18/k188rsfh7d
                                    </a>.
                                </>
                            }
                        </div>
                        <div className="mt-8 flex space-y-6 sm:space-x-4 sm:space-y-0 flex-column sm:flex-row sm:justify-around flex-wrap">
                            <img
                                src={process.env.PUBLIC_URL + `/images/activities/un_website.jpg`}
                                alt="First Image Description"
                                className="sm:max-w-[48%] rounded-lg shadow-md aspect-auto"
                            />
                            <img
                                src={process.env.PUBLIC_URL + `/images/activities/un_meeting.jpg`}
                                alt="Second Image Description"
                                className="sm:max-w-[48%] rounded-lg shadow-md aspect-auto"
                            />
                        </div>
                    </div>
                </div>
                <div className={"w-full max-w-6xl mx-auto flex flex-wrap justify-space-between items-start"}>
                    <a className={"w-[95%] sm:w-full block"} href={"https://www.eventbrite.com/e/paris-ai-action-summit-2025-side-event-on-ai-governance-tickets-1207984938469?aff=oddtdtcreator"}>
                        <div className={`w-full${isChinese ? ' md:min-h-[150px]' : ' md:min-h-[150px]'} border border-neutral-100 hover:border-[#c00000] flex flex-col items-start justify-start shadow hover:shadow-lg transition duration-200 rounded-lg py-12 px-4 md:px-16 mx-3 my-3`}>
                            <div className={"text-[#c00000] text-3xl font-semibold font-sans leading-relaxed min-h-[60px]"}>
                                {isChinese ? '人工智能行动峰会(AI Action Summit)边会：人工智能技术及其应用进展(Progress in AI Technology and Its Application)' : 'AI Action Summit Side Event: Progress in AI Technology and Its Application'}
                            </div>
                            <div className={"mt-6 text-neutral-600 text-lg font-light font-sans leading-relaxed"}>
                                {isChinese ? '2025年2月11日' : 'February 11th, 2025'}
                            </div>
                            <div className={"mt-0 text-neutral-600 text-lg font-light font-sans leading-relaxed"}>
                                {isChinese ? '法国 巴黎' : 'Paris, France'}
                            </div>
                            <div className={"mt-4 text-neutral-600 text-lg font-light font-sans leading-relaxed"}>
                                {isChinese ?
                                    '中国人工智能发展与安全行动网络在法国巴黎举办的人工智能行动峰会(AI Action Summit)期间举办峰会正式边会：人工智能技术及其应用进展(Progress in AI Technology and Its Application)。'
                                    :
                                    'The China AI Development and Safety Network holds an AI Action Summit official side event: Progress in AI Technology and Its Application.'}
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div id={"contact"} className={"flex justify-center items-center flex-col pt-24 px-5 bg-white"}>
                <div className={"w-[100%] sm:w-[75%] lg:w-[85%] xl:w-[75%] max-w-7xl mx-auto mb-10"}>
                    <div className={`block text-4xl md:text-5xl bg-clip-text text-transparent bg-[#c00000] sm:bg-gradient-to-b from-[#c00000] to-[#c00000] font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.03em]' : 'tracking-[.02em]'}`}
                    >
                        {isChinese ? '联系我们' : 'Contact us'}
                    </div>
                </div>
                <div className={"w-[100%] sm:w-[75%] xl:w-[75%] max-w-7xl mx-auto flex justify-center items-center"}>
                    <div className={"text-neutral-600 text-xl font-light font-sans leading-relaxed"}>
                        <span className={``}>
                            {isChinese ? '如有任何疑问和合作需求，请联系我们' : 'For inquiries, please contact us at'}:&nbsp;
                        </span>
                        <a className={``}
                           href="mailto:contact@aisi-network.cn"
                        >
                            contact@aisi-network.cn
                        </a>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home