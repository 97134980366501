import React, {lazy, Suspense, useRef, useState} from 'react';
import {BrowserRouter, Routes, Route, Link, useNavigate, ScrollRestoration} from 'react-router-dom'
import {Helmet, HelmetProvider} from 'react-helmet-async';
import './App.css';
import './styles/Common.css';
import 'animate.css/animate.min.css';

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Institutes from "./components/Institutes";
import ResearchHighlights from "./components/ResearchHighlights";
import Article from './components/Article';
import Bottombar from "./components/Footer";
import ScrollToTopOnMount from './hooks/ScrollToTopOnMount';
import ArticlesOuter from "./components/ArticlesOuter";

const App = () => {
    const renderNavbarAndBottombar = () => {
        return (
            <>
                {/*<ScrollRestoration/>*/}
                <ScrollToTopOnMount/>
                <Navbar/>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/institutes" element={<Institutes/>}/>
                        <Route path="/research-progress" element={<ResearchHighlights/>}/>
                        {/*                        <Route path="/articles" element={<ArticlesOuter />} >
                            <Route index element={<People />} />
                            <Route path=":slug" element={<Article />} />
                        </Route>*/}
                        <Route exact path="/articles/:slug" element={<Article/>}/>
                    </Routes>
                </Suspense>
                <Bottombar/>
            </>
        );
    };

    return (
        <HelmetProvider>
            <BrowserRouter>
                {renderNavbarAndBottombar()}
            </BrowserRouter>
        </HelmetProvider>
    );
};

export default App
